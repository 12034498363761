
import { Component, Prop, Vue } from 'vue-property-decorator'
import { parseEntityId } from '@/api/wikidata'
import { EntityId } from '@/api/types'

@Component
export default class NotFoundView extends Vue {
  @Prop({ required: true }) protected readonly id!: string
  private error: string | null = null

  private created() {
    try {
      parseEntityId(this.id)
    } catch (err) {
      this.error = err.message
    }
  }
}
